
  import {
    Component,
    Vue,
    Inject,
    Prop,
    Watch,
    Ref
  } from "vue-property-decorator";
  import { ElForm } from "element-ui/types/form";
  import api from "@/api";
  import {DepartmentCreateOrUpdateDto} from "@/api/appService";


  @Component
  export default class EditDepartment extends Vue {
    @Ref() readonly dataForm!: ElForm;

    @Prop({required:true})
    parentId!:number;

    @Prop({required:true})
    dataId!:number;

    defaultData: DepartmentCreateOrUpdateDto = {
      displayName: undefined,
      name:undefined,
      parentId: undefined,
      displayOrder:undefined,
      id: 0
    };

    show = false;
    form: DepartmentCreateOrUpdateDto = { ...this.defaultData };

    get dialogTitle() {
      return this.form!.id ? "编辑" : "新建";
    }

    @Watch("show")
    async onShowChange(value: boolean) {
      if(this.dataId) {
        await api.department.get({ id: this.dataId }).then(res => {
          this.form = {...res};
        });
      }
      if (!value) {
        this.form = { ...this.defaultData };
      }
      this.$nextTick(() => {
        this.dataForm.clearValidate();
      });
    }



    async save() {
      console.log(this.form);

      if(!this.form.id){
        if(this.parentId){
          this.form.parentId = this.parentId;
        } else {
          this.form.parentId = undefined;
        }
      }

      this.dataForm.validate(async (valid: boolean) => {
        if (valid) {
          if (this.form!.id) {
            await api.department.update({ body: this.form });
          } else {
            await api.department.create({ body: this.form });
          }
          this.show = false;
          this.$message.success("更新成功");
          this.$emit("onSave");
        }
      });
    }


    cancel() {
      this.show = false;
    }

    departmentRule = {
      displayName: [
        {
          required: true,
          message: "必填",
          trigger:['blur','change']
        }
      ],
      name:[
        {required:true,message:'必填',trigger:['blur','change']}
      ]
    };
  }
