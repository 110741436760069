
  import api from "@/api/index"; //ABP API接口
  import { Vue, Component, Ref } from "vue-property-decorator";

  import { MessageBox } from "element-ui";
  import enumFilter from "@/mixins/filters/enums";

  import { DepartmentDto } from "@/api/appService";
  import EditDepartment from "./edit.vue";
  import { createTableTree } from "@/utils/tree";

  @Component({
    name: "DepartmentList",
    components:{
      EditDepartment
    }
  })
  export default class DepartmentList extends Vue {
    @Ref() editForm!: EditDepartment;

    tableItems: DepartmentDto[] = [];

    table = {
      listLoading:true
    } as any;

    parentId:number = 0;
    dataId:number = 0;
    async created() {
      await this.fetchData();
    }

    // 获取表数据
    async fetchData() {
      this.table.listLoading = true;
      await api.department.getAll({maxResultCount:14211}).then(res => {
        const tree = createTableTree(
          res.items!,
          "parentId",
          "id",
          null,
          "children"
        );
        this.tableItems = tree;
        this.table.listLoading = false;
      });
    }

    // 新建
    handleCreate() {
      this.parentId = 0;
      (this.editForm as any).show = true;
    }

    handleCreateChild(row:DepartmentDto){
      this.parentId = row.id!;
      (this.editForm as any).show = true;
    }

    // 编辑
    handleEdit(index: number, row: any) {

      (this.editForm as any).dataId = row.id;
      (this.editForm as any).show = true;
    }

    // 删除
    async handleDelete(index: number, row: DepartmentDto) {
      this.$confirm("你确定删除吗?", "提示").then(async () => {
        await api.department.delete({ id: row.id }).then(res => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.fetchData();
        });
      });
    }

    async handelOnSaved() {
      await this.fetchData();
    }
  }
