
import { Component, Vue } from "vue-property-decorator";
import { UserDto } from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({})
export default class UserDetail extends Vue {
  userId?: number;
  detail: UserDto = {};
  roleNames?: String;
  created() {
    if (this.$route.params.id) {
      this.userId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }
  // 获取详情
  async fetchDetail() {
    await api.user.get({ id: this.userId }).then(res => {
      this.detail = { ...res };
      if (this.detail && this.detail.name) {
        this.roleNames = this.detail.roleNames?.join("，");
      }
    });
  }
  // 返回
  private cancel() {
    this.$router.back();
  }
}
