
import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";
import { ResetPasswordDto } from "@/api/appService";
import { createNgTree, rebuildKeys, getKeys } from "@/utils/tree";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "ResetPassword"
})
export default class ResetPassword extends Vue {
  @Ref() readonly dataForm!: ElForm;

  defaultData: ResetPasswordDto = {
    adminPassword: undefined,
    newPassword: undefined,
    userId: undefined
  };

  @Prop({ required: true })
  userId!: number;
  show = false;
  form: ResetPasswordDto = { ...this.defaultData };
  cancel() {
    this.show = false;
    this.form = { ...this.defaultData };
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.form.userId = this.userId;
        debugger
        await api.user.resetPassword({
          body: this.form
        });
        this.show = false;
        this.$message.success("更新成功");
      }
    });
  }

  ruleRule = {
    adminPassword: [
      {
        required: true,
        message: "请输入管理员密码",
        trigger: "blur"
      }
    ],
    newPassword: [
      {
        required: true,
        message: "请输入新密码",
        trigger: "blur"
      }
    ]
  };
}
