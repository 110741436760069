
import {Component, Vue} from "vue-property-decorator";
import {UserDto, UserDtoPagedResultDto} from "@/api/appService";
import api from "@/api";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {UserModule} from "@/store/modules/user";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import ResetPassword from "@/views/ou/user/resetPassword.vue";

interface UserQueryForm {
  keyword: string;
  organizationUnitId?: number;
}

@Component({
  components: {
    ResetPassword,
    OrganizationSelect,
    PagedTableView
  }
})
export default class OrganizationUnitUsers extends Vue {
  tableItems: UserDtoPagedResultDto[] = [];

  queryForm: UserQueryForm = {
    keyword: "",
    organizationUnitId: undefined
  };

  userId: number = 0;
  clearable: boolean = false;

  created() {
    const ou = UserModule.getOU;
    if (ou?.id) {
      this.queryForm.organizationUnitId = ou.id;
    } else {
      this.queryForm.organizationUnitId = undefined;
    }
  }

  get showOrganizationUnitSelect() {
    return (!!UserModule.getOU?.id) === false;
  }

  // 获取表数据
  fetchData(params: any) {
    params.mode = 'organizationUnit';
    return api.user.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push(
      {
        name: "usersCreate",
        query: {
          mode: 'organizationUnit',
          organizationUnitId: (this.queryForm.organizationUnitId?.toString() ?? '')
        }
      });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "reset-password": {
        this.handleResetPassword(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
    }
  }

  // 编辑
  handleEdit(index: number, row: UserDto) {
    this.$router.push({
      name: "usersEdit",
      params: {id: row.id!.toString()},
      query: {
        mode: 'organizationUnit'
      }
    });
  }

  // 查看详情
  jumpDetail(index: number, row: UserDto) {
    this.$router.push({
      name: "usersDetail",
      params: {id: row.id!.toString()},
      query: {
        mode: 'organizationUnit'
      }
    });
  }

  // 删除
  async handleDelete(index: number, row: UserDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.user.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  // 重置密码
  handleResetPassword(index: number, row: UserDto) {
    this.userId = row.id!;
    (this.$refs.resetPasswordForm as any).userId = this.userId;
    (this.$refs.resetPasswordForm as any).show = true;
  }

}
